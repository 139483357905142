import React from 'react';
import { SearchBar, Text } from '..';
import "./SubHeader.css";
import { useTranslation } from 'react-i18next';

const SubHeader = ({ title, showSearch = false, placeholder="SearchDestination_placeholder", onSearchChange, searchQuery }) => {
  const { t } = useTranslation();

  return (
    <div className='SubHeader-main'>
      <Text heading whiteColor>{title}</Text>
      {showSearch && (
        <div className='SubHeader-SearchBar'>
          <SearchBar
            value={searchQuery}
            placeholder={t(placeholder)}
            onChange={(e) => onSearchChange(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}

export default SubHeader;
