import { Email, Facebook } from '@material-ui/icons';

export const Images = {
  mainlogo: require('../../assets/images/mainlogo.png'),
  background: require('../../assets/images/background.png'),
  cart: require('../../assets/images/cart.png'),
  Contactus_Phone: require('../../assets/images/contactus_phoneic.png'),
  Contactus_Email: require('../../assets/images/contactus-email_ic.png'),
  Contactus_Location: require('../../assets/images/contactus_location_ic.png'),
  Contactus_captchaIc: require('../../assets/images/Contactus_captchaIc.png'),
  Facebook_IC: require('../../assets/images/ic_fb logo.png'),
  Earth_IC : require('../../assets/images/earth.png'),
  super_payment : require('../../assets/images/super_payment.png'),
  stripe_payment : require('../../assets/images/stripe_payment.png'),
  ArrowIcon : require('../../assets/images/DestinationCardIcon.png'),
  QR_Code : require('../../assets/images/QR_code.png'),

  FR: require('../../assets/images/flag/France.png'),
  SP: require('../../assets/images/flag/spain.png'),
  US: require('../../assets/images/flag/US.png'),
  CN: require('../../assets/images/flag/CN.png'),
  IT: require('../../assets/images/flag/IT.png'),
  TR: require('../../assets/images/flag/TR.png'),
  UK: require('../../assets/images/flag/UK.png'),
  AU: require('../../assets/images/flag/AU.png'),

  Group_ic : require('../../assets/images/Group.png'),
  Smile_ic : require('../../assets/images/Smile.png'),
  Dollar_ic : require('../../assets/images/Dollar.png'),
  shield_ic : require('../../assets/images/shield_ic.png')

}
