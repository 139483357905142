

export const GetBaseURL = () => {
    var HostName = window.location.hostname
    var ReturnURL = '';
    if (HostName.indexOf("elitebdm.thestaging.cc") >= 0) {
        ReturnURL = 'https://testapi.e-simlife.com'
    }
   else if (HostName.indexOf("e-simlife.com") >= 0) {
        ReturnURL = 'https://api.esim-life.com'//DO NOT USE THIS. IT IS LIVE
    }
    else {  //Local
        ReturnURL = 'https://testapi.e-simlife.com'
        // ReturnURL = 'https://testapi.esim-life.com'

        // return 'https://api.esim-life.com'//DO NOT USE THIS. IT IS LIVE

    }
    return ReturnURL
}

// export const IsDevelopment = () => {
//     var HostName = window.location.hostname

//     if (HostName.indexOf("elitebdm.thestaging.cc") >= 0) {
//         return true
//     }
//     else {  //Live
//         return false
//     }
// }
export const IsDevelopment = () => {
    var HostName = window.location.hostname
    if (HostName.indexOf("e-simlife.com") >= 0) {
        return false
    }
    else {  //Live
        return true
    }
}

export function GetSuperPaymentURL() {
    if (IsDevelopment) {  //Development
        return 'https://api.test.superpayments.com/v2/payments' // testing url
    } else {    //Live
        return 'https://api.superpayments.com/v2/payments' // Live url
    }
}

export function GetSuperPaymentToken() {
    if (IsDevelopment) {  //Development
        return `PSK_RKM38qEYTbVn7qMEqNJVSFekfBQpyoHQoQeDv--K`// Testing Key
    } else {  //Live
        return `PSK_KN7ESoIdKhSTkoKVtzATD-653y40UWl3SiazMl8l` // Live Key
    }
}
export const SuperPaymentRedirectURL = {
    'Sucess': (IsDevelopment) ? 'https://elitebdm.thestaging.cc/PaymentSuccess' : 'https://e-simlife.com/PaymentSuccess',
    'Cancel': (IsDevelopment) ? 'https://elitebdm.thestaging.cc/PaymentFailure' : 'https://e-simlife.com/PaymentFailure',
    'Failure': (IsDevelopment) ? 'https://elitebdm.thestaging.cc/PaymentSuccess' : 'https://e-simlife.com/PaymentFailure',
}
export const StripePaymentRedirectURL = {
    'Sucess': (IsDevelopment) ? 'https://elitebdm.thestaging.cc/PaymentSuccess' : 'https://e-simlife.com/PaymentSuccess',
    'Cancel': (IsDevelopment) ? 'https://elitebdm.thestaging.cc/PaymentFailure' : 'https://e-simlife.com/PaymentFailure',
    'Failure': (IsDevelopment) ? 'https://elitebdm.thestaging.cc/PaymentFailure' : 'https://e-simlife.com/PaymentFailure',
}


export const URLs = {
    base: GetBaseURL(),
    Gettoken: '/token',
    RegisterAppNotificationToken: '/API/Users/RegisterBrowserNotificationToken',
    GetMyProfile: '/API/Users/GetMyProfile',
    GetMyNotifications: '/API/Users/GetMyNotifications',
    EditProfile: '/API/Users/EditProfile',
    VerifyOTP: '/VerifyEmail',
    ResendOTP: '/ResendVerificationEmail',
    GetESIMCountries: '/GetESIMCountries',
    GetESIMBundles: '/GetESIMBundles',
    RegisterESIMLife: '/RegisterESIMLife',
    ContactUs: '/ContactUs',
    GetNav: '/API/ACS/GetNav',
    OrderESIM: '/OrderESIM',
    GetESIMOrders: '/GetESIMOrders',
    ViewESIMOrderDetails: '/ViewESIMOrderDetails',
    SendESIMOrderEmail: '/SendESIMOrderEmail',
    PaymentInitiate: '/PaymentInitiate',
    ChangePassword: '/ChangeCustomerPassword',
    ForgotPassword: '/ForgotPassword',
    CustomerGetOffline: '/CustomerGetOffline',
    GetCustomerProfile: '/GetCustomerProfile',
    UpdateCustomerProfile: '/UpdateCustomerProfile',
    SetAffiliateMapping: '/SetAffiliateMapping',
    GetAffiliateBranding: '/GetAffiliateBranding',



    //Google And FaceBook Keys
    googlekey: 'AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI',//google API key
    googleSiginClientID: '486407248016-f3549ujakk7jpsn560rl02fc80u4kjjo.apps.googleusercontent.com',
    Recaptchakey: '6LfS7iIqAAAAAGvnu0IPcUwZtcS-sibR4fNUEblA',//Recaptcha API key

    //STRIPE  PAYMENT INTEGRATION APIS
    GetStripeSecretKeys: 'https://api.stripe.com/v1/payment_intents',

    // SUPER PAYMENT INTEGRATION API
    SuperPaymentToken: GetSuperPaymentToken(),
    SuperPaymentAuth: GetSuperPaymentURL(),
    SuperPaymentRedirectURL: SuperPaymentRedirectURL,
    StripePaymentRedirectURL: StripePaymentRedirectURL,
    SubmitSuperPaymentDetails: 'CorporateSuperPaymentAPI/PaymentWithSuperPaymentSuccess',
    SuperpaymentFailure: 'CorporateSuperPaymentAPI/SuperPaymentFailure',
    SupercancelPayment: 'CorporateSuperPaymentAPI/CancelSuperPayment',

}