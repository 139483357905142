import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { RiMenu3Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AlertMessage, Button, CommonModal, Text } from '../../components';
import { BaseColor, Images } from '../../config';
import { capitalizeFirstLetter, excludedScreensFromLogin, excludedScreensFromLoginWithProps } from '../../config/appConstant';
import { setLoginModal } from '../../redux/store';
import LanguageDropdown from './languageDropdown';
import './navbar.css';
import UserActionsDropdown from './UserActionDrodown';
import { SignIn, SignUp } from '../../config/PagePathList';

const NavbarData = [
  { 'Name': 'Home', 'ID': 0, 'link': '/home' },
  { 'Name': 'Destinations', 'ID': 0, 'link': '/destination' },
  { 'Name': 'MyeSIM', 'ID': 0, 'link': '/myeSIM' },
  { 'Name': 'Get_Help', 'ID': 0, 'link': '/gethelp' },
  { 'Name': 'About_Us', 'ID': 0, 'link': '/aboutus' },
  { 'Name': 'Contact_Us', 'ID': 0, 'link': '/contactus' },
]

const Navbar = ({ IsLogin, props }) => {
  const navigate = useNavigate()

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState('/home');
  const [NavShow, setNavShow] = useState(false);
  const dispatch = useDispatch()
  const [LastNavigatePath, setLastNavigatePath] = useState('');
  const Loginmodalopen = useSelector((state) => state.ol);
  const [alertMessage, setAlertMessage] = useState(t('Login_Required')); // Default message
  const [LoginModalOpen, setLoginModalOpen] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);


  useEffect(() => {
    const splitlink = pathname.split("/");
    const activepath = splitlink[1];
    setActiveLink("/" + activepath)
    let ScreenName = capitalizeFirstLetter(window.location.pathname.split('/')[1])
    document.title = `eSIMLife | ${ScreenName}`;
    console.log('ScreenName:', ScreenName)

  });
  const getAffiliateCodeFromUrl = (url) => {
    try {
      const queryString = url.split('?')[1]; // Get the query string
      if (!queryString) return null;

      // Split the query string into parameters
      const params = queryString.split('&');
      const affiliateParam = params.find(param => param.startsWith('AffiliateCode='));
      if (affiliateParam) {
        // Extract the AffiliateCode value
        return decodeURIComponent(affiliateParam.split('=')[1]);
      }

      return null;
    } catch (error) {
      console.error('Error extracting AffiliateCode:', error);
      return null;
    }
  };
  useEffect(() => {
    // Check if the URL matches the signup path
    if (pathname === '/signup') {
      let affiliateCode = getAffiliateCodeFromUrl(window.location.href)
      if (affiliateCode) {
        handleSignInScreen(affiliateCode)
      }
    }
  }, [pathname]);

  const handleSignInScreen = (affiliateCode) => {
    setLoginModalOpen(true)
    switchToSignUp()
  }

  const handleNavigation = (path) => {
    setLastNavigatePath(path)

    let alertMessage = '';
    if (path === '/myeSIM') {
      alertMessage = 'Please log in to view your available and used eSIMs.';
    }


    if (excludedScreensFromLoginWithProps(path) && !IsLogin) {
      dispatch(setLoginModal(true))
      setAlertMessage(alertMessage);

    } else {
      navigate(path)
    }

  }

  const handleClose = () => {
    setLoginModalOpen(false)
    setShowSignIn(true);

  }

  const switchToSignUp = () => {
    setShowSignIn(false);
  };


  return (
    <nav id='nav' className={NavShow ? 'nav-show' : ''} >
      {Loginmodalopen && <AlertMessage onClose={() => dispatch(setLoginModal(false))} message={alertMessage} LastNavigatePath={LastNavigatePath} />}
      <div className="nav-main">
        <div className="nav-logo">
          <Link to="/home" aria-label='home'><img src={Images.mainlogo} alt='Technolee' className='logo' /></Link>
        </div>
        <input type="radio" name="slider" id="menu-btn" readOnly />
        <input type="radio" name="slider" id="close-btn" checked readOnly />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn"><AiOutlineClose /></label>
          {NavbarData.map((item, key) => {
            return (
              <li key={key}>
                <div onClick={() => handleNavigation(item.link)} className={'active-link'} >{<Text primaryColor={activeLink == item.link ? true : false}>{t(item.Name)}</Text>}</div>
              </li>
            )
          })}
          <div className='cart-devider-div'>
            <div className='cart-devider'></div>
          </div>
          <div className='language-dropdown'>
            <LanguageDropdown />
          </div>
          {IsLogin ?
            <div className='language-dropdown'>
              <UserActionsDropdown IsLogin={IsLogin} />
            </div>
            :
            <div className='language-dropdown button-login-margin'>
              <Button type={2} onClick={() => setLoginModalOpen(true)} title={t('Login')} />
            </div>
          }
        </ul>
        <label htmlFor="menu-btn" className="btn menu-btn"><RiMenu3Fill color={BaseColor.text} /></label>
      </div>


      <CommonModal maxWidth={'xl'} visible={LoginModalOpen} onClose={handleClose}>
        {showSignIn ? <SignIn onSignUpClick={switchToSignUp} onClose={handleClose} /> : <SignUp onClose={handleClose} />}
      </CommonModal>
    </nav >
  );
};

export default Navbar;