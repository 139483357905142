import { jwtDecode } from 'jwt-decode';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../API/API';
import * as APIController from "../../API/APIController";
import { URLs } from '../../API/URLs';
import { Button, Text, TextField } from '../../components';
import MobileInput from '../../components/MobileInput/MobileInput';
import { AppConstant, IsEmpty } from '../../config/appConstant';
import { setLogin, setloginDetail } from '../../redux/store';
import './SignUp.css';

const SignUp = ({ props, onClose }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const { Email, name, userId } = location.state || {};
    const UserDetails = useSelector((state) => state.userdetail);

    const [email, setEmail] = useState(userId ? location.state.Email : '');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [customerName, setCustomerName] = useState(userId ? location.state.name : '');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState(false);
    const [AffiliateCode, setAffiliateCode] = useState('');
    const [CountryCode, setCountryCode] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const paymentIntent = searchParams.get('AffiliateCode');
        if (!IsEmpty(paymentIntent))
            setAffiliateCode(paymentIntent)
    }, [location, navigate]);

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }



    const validateForm = () => {
        if (!customerName) {
            return false;
        } else if (!validateEmail(email)) {
            return false;
        } else if (!phoneNumber) {
            return false;
        } else if ((password.length < 6)) {
            return false;
        } else if (password !== confirmPassword) {
            return false;
        }

        return true;
    }


    const handleSubmit = (e) => {
        setTouched(true);
        e.preventDefault();
        if (validateForm()) {
            wsRegisterESIMLife(customerName, email, phoneNumber, CountryCode, AffiliateCode, password, confirmPassword, UserDetails.IsRegisterComplete == 0 ? UserDetails.ID : '');
        }
    }

    const wsRegisterESIMLife = (CustomerName, EmailAddress, MobileNumber, CountryCode, AffiliateCode, Password, ConfirmPassword, UserId) => {
        setLoading(true)
        APIController.RegisterESIMLife(CustomerName, EmailAddress, MobileNumber, CountryCode, AffiliateCode, Password, ConfirmPassword, UserId)
            .then((response) => {
                if (response.object.Status == 1) {
                    wsLogin(response.object.Message)
                } else {
                    setLoading(false)
                    toast.error(t(response.object.Message));
                }
            })
            .catch((err) => {
                toast.error(t('An error occurred. Please try again.'));
            });
    };
    const wsLogin = (message) => {

        var details = {
            Username: email,
            Password: password,
            Code: AppConstant.CustomerCode,
            grant_type: 'password',
        };
        var formBody = [];
        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');

        let url = URLs.base + '/token';
        return new Promise((resolve, reject) => {
            API.shared.request('POST', url, formBody)
                .then((response) => {
                    setLoading(false);
                    if (response.error == null) {
                        const decoded = jwtDecode(response.jwt);
                        let FullDatetime = moment().format('MM/DD/YYYY HH:mm:ss');
                        let data = {
                            ...decoded,
                            EmailID: email,
                            IsRegisterComplete: 0,
                            ExpiredAt: response.SessionTimeout,
                            TokenDateTimeValid: FullDatetime
                        }
                        dispatch(setloginDetail(data));
                        onClose(false)
                        localStorage.setItem('Access_Token', response.access_token);
                        if (response.IsEmailOTPVerifyRequired === "False") {
                            navigate('/home', { replace: true });
                            dispatch(setLogin(true));

                        } else {
                            navigate('/otp', { state: { email: email } });
                        }
                        toast.success(message)
                    } else {
                        toast.error(response.error_description)
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                });
        });
    };
    const responseFacebook = (response) => {
        console.log(response);
    };

    const responseGoogle = (response) => {
        console.log(response);
    };

    return (
        <>
            <div className=''>
                <div className="login-container">
                    <div className="login-right">
                        <div className='signup-heading'>
                            <Text bold title2>{t('Sign_Up')}</Text>
                        </div>
                        <div className="input-group input-xalign">
                            <div className="input-textfield">
                                <TextField
                                    placeholder={t('Contactus_CustomerName')}
                                    value={customerName}
                                    onChange={(e) => setCustomerName(e.target.value)}
                                    error={customerName === '' && touched}
                                />
                            </div>

                        </div>
                        <div className="input-group input-xalign">
                            <div className="input-textfield">
                                <TextField
                                    placeholder={t('Email_Address')}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    error={!validateEmail(email) && touched}
                                />
                            </div>
                            <div className="input-textfield">
                                <MobileInput
                                    placeholder={t('Mobile No')}
                                    value={`${CountryCode}${phoneNumber}`}
                                    onChange={(phone, countryCode) => {
                                        setPhoneNumber(phone);
                                        setCountryCode(countryCode);
                                    }}
                                    error={phoneNumber === '' && touched}
                                />

                            </div>
                        </div>

                        {!IsEmpty(AffiliateCode) &&
                            <div className="input-group">
                                <TextField
                                    placeholder={t('AffiliateCode')}
                                    label={'Affiliate Code'}
                                    value={AffiliateCode}
                                    disabled
                                    onChange={(e) => setAffiliateCode(e.target.value)}
                                    error={AffiliateCode === '' && touched}
                                />
                            </div>
                        }

                        <div className="input-group input-xalign">
                            <div className="input-textfield">
                                <TextField
                                    placeholder={t('Password')}
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    error={(password === '' || password.length < 6) && touched}
                                />
                            </div>
                            <div className="input-textfield">
                                <TextField
                                    placeholder={t('Confirm Password')}
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    error={(confirmPassword === '' || confirmPassword.length) < 6 && touched}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginBottom: "20px" }}>
                            <Button capsule full type={2} loading={loading} title={t('Register')} onClick={handleSubmit} />
                            {/* <Button style={{ marginLeft: '10px' }} capsule full type={2} onClick={() => navigate('/SignIn')} title={t('Sign_In')} /> */}
                        </div>


                        <div className='signup-checkbox'>
                            <div>
                                <Text DarkGrayTextColor>{t('Sign_up_description_partone')}<a style={{ textDecoration: "none" }} href='./termsandcondition'>  <Text PurpleColor>{t('Sign_up_description_parttwo')}</Text> </a> {t('Sign_up_description_partthree')} <a style={{ textDecoration: "none" }} href="https://www.elitemobile.com/pdf/PrivacyPolicy.pdf?version=16032022" target="_blank"><Text PurpleColor>{t('Sign_up_description_partfour')}</Text></a> </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SignUp;
