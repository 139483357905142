import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { BaseColor, FontFamily, FontWeight, useTheme } from '../../config';
import searchIcon from '../../../assets/images/search (8) 1.png'; // Adjust the path based on your folder structure
import { IconButton } from '@material-ui/core';


const CssTextField = withStyles({
    root: {
        '& label': {
            color: BaseColor.text,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.bold,
            letterSpacing: '0.5px',
            textOverflow: "ellipsis",
            whiteSpace: 'nowrap',
            overflowx: 'hidden'
        },
        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px'
        },
        '& label.Mui-focused': {
            color: BaseColor.text,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px'
            }
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '25px',
                borderColor: BaseColor.mainColor,
                color: BaseColor.text
                
            },
            '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.mainColor
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.mainColor
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 25px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.text,
                fontWeight: FontWeight.regular
            }
        }
    }
})(TextField);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    TextFieldStyle: {
        width: "100%",
        fontSize: 15,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.heavy,
        borderRadius: '25px',  // Increased border radius
        backgroundColor: BaseColor.card
    }
}));

const CustomInputComponent = props => {
    const { maxLength, label, value, shrink, disabled, required, handleSearchIconClick, placeholder } = props;
    // let BaseColor = useTheme().theme.colors

  

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssTextField
                InputProps={{
                    maxLength: maxLength,
                    endAdornment: (
                        <IconButton edge="end" onClick={handleSearchIconClick}>
                            <img src={searchIcon} alt="search icon" style={{ width: '20px', height: '20px', }} />
                        </IconButton>
                    ),
                }}
                inputProps={{ maxLength }}
                InputLabelProps={{ shrink: shrink }}
                label={required ? <span>{label}<sup>*</sup></span> : label}
                required={required}
                fullWidth
                placeholder={placeholder}

                className={classes.TextFieldStyle}
                value={value}
                disabled={disabled}
                variant="outlined"
                {...props}
            />
        </div>
    );
}

CustomInputComponent.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    value: PropTypes.string,
    variant: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.bool,
    placeholder: PropTypes.string,

};

CustomInputComponent.defaultProps = {
    style: {},
    maxLength: 15000,
    value: '',
    name: '',
    label: '',
    variant: 'outlined',
    onChange: () => { },
    required: false,
    error: false,
    placeholder: '',

}

export default CustomInputComponent;
