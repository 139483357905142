import PropTypes from "prop-types";
import React from 'react';
import './TwoSection.css';

//   LayoutType
// 1:both side Same
// 2.Left big and Right small
// 3.Right Big And Left Small

const TwoSection = (props) => {
    const { LeftSection, RightSection, LayoutType, } = props

    return (
        <div>
            <div className='row'>
                <div className={`col-lg-${LayoutType == 2 ? 4 : LayoutType == 1 ? 8 : 6} col-12 myclass`}>
                    {LeftSection}
                </div>
                <div className={`col-lg-${LayoutType == 2 ? 8 : LayoutType == 1 ? 4 : 6} col-12 myclass`}>
                    {RightSection}
                </div>
            </div>
        </div>
    )


}
TwoSection.propTypes = {
    customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    loading: PropTypes.bool,
    small: PropTypes.bool,
    LayoutType: PropTypes.number,
    LeftSection: PropTypes.node,
    RightSection: PropTypes.node,
};

TwoSection.defaultProps = {
    customClass: {},
    isSameLayout: true,
    LeftSection: null,
    RightSection: null,
    LayoutType: 0,
    extraSmall: false,
    loading: false,
};
export default TwoSection;
