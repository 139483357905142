import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CommonModal, Text } from '../../components';
import { IsEmpty } from '../../config/appConstant';
import { SignIn, SignUp } from '../../config/PagePathList';
import { setLoginModal } from '../../redux/store';
import './AlertMessage.css'; // Include the CSS for styling

const AlertMessage = ({ message = 'Login_Required', onClose }) => {
  const { t } = useTranslation();
  const [NavbarAlert, setNavbarAlert] = useState(false);
  const [showSignIn, setShowSignIn] = useState(true);
  const dispatch = useDispatch()


  const handleCountinue = () => {
    setNavbarAlert(true)
  }

  const handleClose = () => {
    onClose(false)
    dispatch(setLoginModal(false))
  }

  const switchToSignUp = () => {
    setShowSignIn(false); 
  };

  return (
    <>
      <div className="alert-overlay">
        <div className="alert-container fade-in">
          <div className="close-icon" onClick={() => handleClose()}>
            &times; {/* You can replace this with an SVG or another icon if desired */}
          </div>
          <Text body1 bold>{t(message)}</Text>
          <div className="alert-buttons">
            <button className="continue-btn" onClick={handleCountinue}>
              <Text whiteColor>
                {t("Continue")}
              </Text>
            </button>
          </div>
        </div>
      </div>
      <CommonModal maxWidth={'xl'} visible={NavbarAlert} onClose={() => handleClose()}>
        {showSignIn ? (<SignIn onSignUpClick={switchToSignUp} onClose={handleClose} />) : (<SignUp onClose={handleClose} />)}
      </CommonModal>
    </>
  );
};

export default AlertMessage;
