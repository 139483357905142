import React, { useState } from 'react';
import BundleList from '../../components/BundleList/BundleList';
import SelectedBundle from '../../components/SelectedBundle/SelectedBundle';
import "./Packages.css";
import { SearchBar } from '../../components';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { IsEmpty } from '../../config/appConstant';

export default function Packages(props) {
    const dispatch = useDispatch();
    const { handeBundleSelect, selectCountryRegion, selectBundle } = props
    const [searchValue, setSearchValue] = useState('');

    const handleChange = (event) => {
        setSearchValue(event.target.value);
    };

    return (
        <>
            <div className='package-searchbar'>
                <SearchBar
                    placeholder={t('Search_Bundle')}
                    maxLength={100}
                    value={searchValue}
                    onChange={handleChange}
                    required />
            </div>
            <div className='Packages-Bundalmain'>
                <div className={`Packages-Bundle-list ${!IsEmpty(selectBundle) ? 'hide-on-mobile' : ''}`} >
                    <BundleList searchValue={searchValue} selectCountryRegion={selectCountryRegion} handeBundleSelect={handeBundleSelect.bind(this)} />
                </div>
                <div className='Packages-Selected-Bundle'>
                    <SelectedBundle selectCountryRegion={selectCountryRegion} handeBundleSelect={handeBundleSelect.bind(this)} selectBundle={selectBundle} />
                </div>
            </div>
        </>

    )
}
