import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import PropTypes from "prop-types";
import React from "react";
import { Images } from "../../config";
import "./modal.css";


const ModalComponent = (props) => {
	const { show, children, IsCloseIcon, IsLibrary, IsTrasparent, OuterClose, handleClose, } = props;
	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			className={`modal-main`}
			open={show}
			onClose={OuterClose === false ? handleClose : ''}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}>
			<Fade in={show}>
				<div className={IsTrasparent ? "popup-wrap trasparent-popup" : "popup-wrap"} >
					{children}
					{IsCloseIcon && <img src={Images.cross} className={IsLibrary ? 'gray-close-ic-for-location' : "gray-close-ic"} alt="icon" onClick={handleClose} />}
					{/* {IsCloseIcon && <a title="" className={IsLibrary ? 'gray-close-ic-lib' : "gray-close-ic"}>
						<img src={Images.cross} style={{ height: '100%', width: '100%' }} alt="icon" onClick={handleClose} />
					</a>} */}
				</div>

			</Fade>
		</Modal>
	);
}

ModalComponent.propTypes = {
	style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
	handleClose: PropTypes.func,
	show: PropTypes.bool,
	IsTrasparent: PropTypes.bool,
	IsCloseIcon: PropTypes.bool,
	OuterClose: PropTypes.bool,
	IsLibrary: PropTypes.bool,

};

ModalComponent.defaultProps = {
	show: false,
	OuterClose: false,
	IsLibrary: false,
	IsTrasparent: false,
	IsCloseIcon: true,
	handleClose: () => { },
};


export default ModalComponent;
