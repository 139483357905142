import moment from "moment";
import { URLs } from "../API/URLs";
import { v4 as uuidv4 } from 'uuid';
import { Images } from "./Images";

var CryptoJS = require("crypto-js");
let LocationObject = {
    City: '',
    PinCode: '',
    FullAddress: '',
    State: '',
    Country: ''
}
const defaultDateFormats = {
    DDMMYYYY: 'DD/MM/YYYY',
    MMDDYYYY: 'MM/DD/YYYY',
    MM_DD_YYYY: 'MM-DD-YYYY',
    FormatDate: 'DD-MMM-YY',
    FormatDateCMMS: 'DD/MM/YYYY',
    SingleDatePiker: 'YYYY-MM-DD'
}

function GetDateWithMyFormat(date) {
    return moment(date).format(defaultDateFormats.FormatDate)
}

function GetCMMSDateWithMyFormat(date) {
    return moment(date).format(defaultDateFormats.FormatDateCMMS)
}

function GetDateWithCommonFormat(date) {
    return moment(date).format(defaultDateFormats.MMDDYYYY)
}
function GetDateWithMM_DD_YYYY(date) {
    return moment(date).format(defaultDateFormats.MM_DD_YYYY)
}
function GetDateWithSingleDatePiker(date) {
    return moment(date).format(defaultDateFormats.SingleDatePiker)
}

function ConvertToDateMMDDYYYY(dateString) {
    const parts = dateString.split("-");
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    return formattedDate;
};

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const GetMonthNumber = (monthName) => {
    const monthIndex = monthNames.indexOf(monthName);
    if (monthIndex === -1) {
        return monthName
    }
    return monthIndex + 1; // Adding 1 because indexOf returns 0-based index
};

export const UUID = () => {
    return uuidv4()
}

export const GetDataFromURL = (url) => {
    const idRegex = /[?&]id=([^&]+)/;
    const confirmationRegex = /confirmation/;
    const containsConfirmation = confirmationRegex.test(url);
    const match = idRegex.exec(url);
    const id = match && decodeURIComponent(match[1]);
    let FunReturn = { TransactionId: id, containsConfirmation: containsConfirmation }
    return FunReturn
}

const DefaultLatLng = { lat: 40.217650, lng: -96.927330, IsLocationVerified: false }

const Constanttitle = {
    name: 'Cobrand',
    desc: 'Cobrand - React.js',
    Title1: "WELCOME TO BAUSCH + LOMB REACH",
    Title2: "A practice marketing platform with tools to help you reach patients through innovative technology",
    OrderDetails: "Order Details",
    ProductCost: "Product Cost",
    Producttitle: "Email Marketing:",
    ProductSubtitle: "Customize, Copy, Send.",
    Ordertitle: "My Orders.",
    Ordersubtitle: "View Details.",
    CustomPromotitle: "Custom Promo Pages.",
    CustomPromosubtitle: "Edit and Manage",
    LocalAdtitle: "Local Ad Campaigns.",
    LocalAdsubtitle: "Customized for Your Practice.",
    Producttitle: "Email Marketing:",
    Hometitle: "WELCOME TO REACH",
    Homeleft: "Practice Marketing Service. ",
    HomeRight: "Targeted Campaigns for Maximum Engagement.",
    HomeDownLeft: " Practice Support Assets.",
    HomeDownright: " Elevate Efficiency, Enhance Practice Impact.",
    Featuredtitle: "Select All: set Featured Pratice Info For Your Campaign(s)",
    Featuredlefttitle: "Featured Pratice Logo",
    PracticeLogo: "Practice Logo",
    Practicetitle: "Practice Information",
    UserProfile: "User Profile",
    Annualsupplytitle: "Annual Supply Calculator Profile",
    CostToolProfile: "Cost Tool Profile",
    Annualsupplyrighttext: "Enter your standard pricing for each Bausch + Lomb lens brand and indication.Click <b className='annualsupply-bold' >CONFIRM.</b> Prices will auto-populate in the Annual Supply Cost Calculator on the top menu.You may update pricing anytime.",
    Annualsupplycalculatertitle: "Annual Supply Calculator.",
    Annualsupplycalculaterheading: "Here`s What Your Bausch + Lomb Contact Lenses Will Cost",
    Dirctmailtitle: "Direct Mail & Print.",
    Dirctmailsubtitle: "Customize, Print, or Download.",
    Changepassword: "Change Password",
    Mediatitle: "Media Library.",
    Mediasubtitle: "Upload, Favorite, and Select from Stock images.",
    Cart: "Your Cart",
    CopiedClipboard: "Content Copied to Clipboard",
    PrivacyPolicysubtitle: "COBRAND MEDIA ONLINE TERMS AND CONDITIONS",
    clinicregistrationcardtitle1: "Practice Info",
    clinicregistrationcardtitle2: "Member Ships",
    clinicregistrationcardtitle3: "Additional Info",
    clinicregistrationcardtitle4: "Practice Logo",
    rightimginformation1: "-Accpeted Files : PNG & JPG",
    rightimginformation2: "-PNG with transparent background recommended",
    rightimginformation3: "-Recommended logo size: 1050 pixels X 750 pixels",
    rightimginformation4: "-Smaller logos may appear pixelated in print products",
    VsMember: "Vision Sources Member Practice",
    VSPMember: "VSP Premier Member Practice",
    registrationaddanotherlocation: "Do you want to add another location to this account?",
    registrationtrems: "Please confirm that you agree to the terms & conditions.",
    registrationpracticetext: "• Accepted files: PNG & JPG • Recommended logo size: 1050 pixels X 750 pixels • Smaller logos may appear pixelated in print products",
    addsocialpostAI1: "To utilize our AI tool for composing your social media posts, simply provide a description of the content you desire here",
    addsocialpostAI2: "Alternatively, you can directly input your desired post copy into the designated 'Post Copy' text box to the left. Please ensure clarity and specificity in your description for optimal AI-generated results.",
    addsocialpostNote: "Note: For Bausch + Lomb posts,please use content from our library. Competitive brand posts may not be added to your celender.",
    addsocialpostaddpost: "Select your social platform(s), post date and time then click CONFIRM to add your post to the calendar.",
    featurepracticefirstparagraph: "Enter the featured location information you'd like to showcase in your marketing campaign(s). Opting include ALL locations trigger the automatic generation of a custom doc finder page. This doc finder function as a centralized promotional site directly linked to your campaign(s), seamlessly connecting consumers to your practices and offers.",
    featurepracticeverifytext: "Verify your facebook and/or Instagram credentials below for REACH to post approved Bausch + Lomb content on your social bussiness pages. Credential verification is done directly with Facebook and Instagram; REACH does not store or access these credentials.",
    CustomPostFeedPagetitle: "Custom Post Feeds",
    Artworkinfopagetitle: "ARTWORK INFO",
    CampainOptiontitle: "Campaign Options",
    LocationNewSocial: "Select a location to enter/update your social media credentials",
    LocationNew: "Select the Location(s) you would like to include in your campaign then click the Next Button.",
    locationNewTitle: "Use Bausch + Lomb recommended targeting"
}


const NumberValidation = (event) => {
    const input = event.target.value;
    // Remove non-numeric characters
    const cleanedInput = input.replace(/\D/g, '');
    // Limit input to 10 digits

    let formattedPhoneNumber = '';
    if (cleanedInput.length <= 10) {
        formattedPhoneNumber = cleanedInput.replace(
            /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
            '($1) $2-$3'
        );
    } else {
        formattedPhoneNumber = cleanedInput.slice(0, 10).replace(
            /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
            '($1) $2-$3'
        );
    }
    return formattedPhoneNumber
};

const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = (match[1] ? '' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

const isValidURL = (url) => {
    const pattern = new RegExp(/^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.(com|in|org|net|edu)/); // fragment locator
    return pattern.test(url);
};
const websiteURLFromat = (url) => {
    var websiteFromat = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return websiteFromat;
}

const NumberDigitValidation = (event, digit) => {
    const input = event.target.value;
    // Remove non-numeric characters
    // const cleanedInput = input.replace(/\D/g, '');
    const cleanedInput = input.replace(/[^\d.]/g, '');
    // Limit input to 10 digits

    const truncatedInput = cleanedInput.slice(0, digit);
    return truncatedInput
};

const SearchSuggestions = (value, suggestionData) => {
    const mockSuggestions = suggestionData

    // Filter suggestions based on the input value
    const filteredSuggestions = mockSuggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
    );

    return filteredSuggestions;
};


const AppConstant = {
    StickyColor: '#fff',
    Pagination: [10, 25, 50, 100, 200, 500, 1000],
    DefaultSort: 'DESC',
    SortDirection: 'ASC',
    DefaultRecordsPerPage: 25,
    SuperPaymentCurrency: 'GBP',
    BrandWebsite: 'esim-life.com',
    BrandMobileNo: '+ 44(0)1827 307777',
    FooterCopyRigthtText: 'Elite Mobile Limited - All Rights Reserved',
    CustomerCode: 'XYZ123',
    ResetCustomerCode: 'CustomerCode'
}

const chipData = [
    { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': "NO", name: "NO", width: '60px' },
    { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "YES", name: 'YES', width: '60px' },
    { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': "No Select", name: 'No Select', width: '100px' },
    { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "Single Select", name: 'Single Select', width: '100px' },
    { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "Multi Select", name: 'Multi Select', width: '100px' },
    { 'color': "#0a34b1b8", 'backgroundColor': '#1237df4a', 'value': "Pending Approval", name: 'Pending', width: '100px' },
    { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "Approved", name: 'Approved', width: '100px' },
    { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': "Rejected ", name: 'Rejected ', width: '100px' },
    { 'color': "#1a1110c7", 'backgroundColor': '', 'value': "WorkOrders", name: 'WorkOrders', width: '60px' },
    { 'color': "#20b756", 'backgroundColor': '#12df2942', 'value': '1', name: 'Created', width: '100%' },
    { 'color': "#d582a4", 'backgroundColor': '#df12ac4a', 'value': '2', name: 'Acknowledged', width: '100%' },
    { 'color': "#0a34b1b8", 'backgroundColor': '#1237df4a', 'value': '3', name: 'Assigned', width: '100%' },
    { 'color': "#6D225C", 'backgroundColor': '#DD92CB', 'value': '4', name: 'Started', width: '100%' },
    { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': '5', name: 'Completed', width: '100%' },
    { 'color': "#bf762a", 'backgroundColor': '#ffa2337a', 'value': '6', name: 'Closed', width: '100%' },
    { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': '7', name: 'Cancelled', width: '100%' },
    { 'color': "black", 'backgroundColor': '#80808099', 'value': "" }]

const IsEmpty = (data) => {
    return (data == null || data.length === 0 || data == undefined || data == '' || data == "0");
}

const isEmptyObject = (data) => {
    return (Object.keys(data).length === 0 && data.constructor === Object)
}

// Social Automation 1
// Local Ad 2
// Custom Promo (Landing page) 3
// Email Marketing 4
// Direct Mail 5
// Free Assets 6

const HomeScreenNavigate = (ModuleNo) => {
    switch (ModuleNo) {
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
            return "/productselection";
        case 6:
            return "/LocalAd";
        default:
            return '/home';
    }
}

// Navigate, Customize 1
// Preview popup 2

const GetButtons = (ID) => {
    switch (ID) {
        case 1:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
            return [{ Name: 'View', Action: 2 }]
        case 2:
            return [{ Name: 'Order', Action: 1 }, { Name: 'Preview', Action: 2 }]
        case 3:
            return [{ Name: 'Download', Action: 5 }, { Name: 'Copy Text', Action: 6 }]
        case 4:
        case 5:
            return [{ Name: 'Customize', Action: 1 }, { Name: 'Preview', Action: 2 }]
        case 6:
            return [{ Name: 'View Post', Action: 3 }, { Name: '+ Calendar', Action: 4 }]
        default:
            return []
    }

}
export const bytesToSize = bytes => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
    return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + sizes[i];
};

export function formatCurrency(amount) {
    return `£${amount.toFixed(2)}`;
}

const ProductScreenNavigate = (ModuleNo) => {
    switch (ModuleNo) {
        case 1:
            return "/locationnew";
        case 3:
            return "/my-landing-page"
        case 2:
        case 4:
        case 5:
            return "/LocalAd"
        default:
            return '/home';
    }
}

// Social Media Ads	1
// Display Ads	2
// Doc Finder	3
// Promo Page	4
// Free Trial	5
// Email Marketing	6
// Postcard Front	7
// Postcard Back	8
// Social Automation	9
// Free Assets	10

const DOCUMENTS = [
    {
        title: 'Upload Front Image',
        SequenceNo: 0,
        DocumentName: '',
        DocumentData: '',
        DocumentURL: '',
        Sync: 0,
    }
]
const FileExtension = {
    TypeCSV: '.csv',
    TypeSVG: '.svg',
    TypeZIP: '.zip',
    TypeHTML: '.html',
    TypeMP4: '.mp4',
    TypePNG: '.png',
    TypeJPG: '.jpg',
    TypeJPEG: '.jpeg',
    TypePDF: '.pdf',
    TypeGIF: '.gif',
}

const TableColor = { main: 'red', buttonFontColor: "#ffff" }
const DocumentTypes = {
    Category: 1,
    ProductTile: 2,
    ProductTileHover: 3,
    ProductDescription: 4,
    ProductImages: 5,
    ProductAssets: 6,
    ProductAssetPreview: 7,
    ProductSubCategoryAsset: 8,
    ProductSubCategoryAssetPreview: 9,
    LocationLogo: 10,
    LocationPhoto: 11,
    CalendarAsset: 12,
    CustomAsset: 13,
    WelcomeScreenPopup: 14,
    ScreenBanner: 15,
    OrderDetailsLogo: 16,
    SupplyToolBanner: 17,
    SupplyToolFooter: 18,
    SupplyToolLead: 19,
    FaviconIcon: 20,
    LoginScreenImage: 21,
    LoginHeaderLogo: 22,
    LoginFooterLogo: 23,
    HeaderLogo: 24,
    FooterLogo: 25,
    CalendarAssetPreview: 26,
    MediaLibrary: 27,
    CustomPostEmailAttachment: 28,
    LoginHeaderBar: 29,
    ProfilePic: 30
}

const facebookConfig = {
    client_id: "584550876158294", // Live
    fields: 'email,picture',
    fbscope: 'public_profile,pages_show_list,pages_manage_posts',
    instascope: 'public_profile,instagram_basic,instagram_content_publish'
}



const GetLatLongFromAddress = (address) => {
    const apiKey = URLs.googlekey; // Replace with your actual API key
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
    let latitude = '';
    let longitude = '';
    fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data.status === "OK") {
                const location = data.results[0].geometry.location;
                latitude = location.lat;
                longitude = location.lng;
                return { latitude, longitude }
            } else {
                console.error("Error:", data.status);
            }
        })
        .catch(error => {
            console.error("Error fetching data:", error);
        });
}


const StaticApiToken = 'bl12lbTS67guMwpXP8Q9wgaGSbJODzQCOguXzjDX1pGkLw67HlDiOGnYluDAz_hD6c99U40YasHoESQiB9dMUMQTQj4Yeihj7AoSf1rzEUtbTWGmv_j8ABk6rQUbXuwE9PJ-d5t5RArc1qIRZhZLlKkk8KBF4LmzPORe7vVvilprmVYHkXw8zZ8xz0nUZN_dNqt9MTlGdl6605JkbJRtRYwov9uH4ZZpZsG39L7Bv-0kpKsgbMBBNFMfuHdrSnbvaLKHXaFd4_bn2vpliUjmEeOZIwfVf19ESHAw5jBhWUIb5fO7nBuC0a61mNnvalT5pQm-keR8ClAwsR0Sp7lswtq-KCBJWd409oyRhXUEv4wnFTE6boVu99qpUmtryvzLt4jA0C23nHyqB5Ev7vLvnFWwBjnMU135udCml_M84u3VrBjtkwJJPkbUUNnk2UadRtGOVM5T_eJ0M01UHiWz8culJwUkIygle2JOA4_5SNPVVNkEIvG1Yo7TSPSsXKlP1cnjrLS16LzocUM0GF-bg7Q7ajj0J5HtbIBxDNdL2GKz0ipW99uwqEYfYM4jYBI21nAEJc6v5oWO2TYm-o49n_MU6GzjZgiJ9eppoaBsA3_mHBchYZATuI7fZ9VivXgjaRmLUNYIcTVrG-frnWqP1TNpgGvevkhS6Q-4gEsxF_U'

const DemoCoords = [
    { lat: 29.047487, lng: 41.023164 },
    { lat: 29.0459633, lng: 41.0212904 },
    { lat: 29.0449333, lng: 41.0167573 },
    { lat: 29.0393543, lng: 41.0106695 },
    { lat: 29.032917, lng: 41.0049697 },
    { lat: 29.0226173, lng: 41.0061356 },
    { lat: 29.0078545, lng: 41.0039334 },

]


export const secretKey = 'Elit-eSIM-v1'

const storageKeys = {
    'IsLoged': 'I-L',
    'UserID': 'U-ID',
    'RoleID': 'R-ID',
    'UserName': 'U-N',
    'CartCount': 'C-C',
    'UserDetail': 'U-D',
    'Language': 'L-G',
    'Theme': 'T-E',
    'Direction': 'D-R',
    'DarkMode': 'D-M',
    'SelectBundle': 'S_B',
    'SET_ICCID': 'S_ICCID',
    'OpenLoginModal': 'O-L'
}

export const excludedScreensFromLogin = () => {
    const excludedScreens = ['/home', '/destination', '/gethelp', '/aboutus']; // Update with route paths
    const currentPath = window.location.pathname;
    if (excludedScreens.includes(currentPath)) {
        return false
    }
    else {
        return true
    }
}
export const excludedScreensFromLoginWithProps = (currentPath) => {
    const excludedScreens = ['/home', '/destination', '/gethelp', '/aboutus','/contactus']; // Update with route paths
    if (excludedScreens.includes(currentPath)) {
        return false
    }
    else {
        return true
    }
}
export const excludedScreensFromNavBar = () => {
    const currentPath = window.location.pathname.toLowerCase(); // Convert current path to lowercase
    const excludedScreensFromNav = ['/otp', '/changepassword']; // Update with route paths in lowercase
    const isExcluded = excludedScreensFromNav.map(path => path.toLowerCase()); // Convert paths to lowercase
    if (isExcluded.includes(currentPath)) {
        return false;
    } else {
        return true;
    }
}


export function isValidToken(dateTime, minutesToExpire) {
    // Convert the dateTime string into a moment object (assuming format: 'MM/DD/YYYY HH:mm:ss')
    const givenDateTime = moment(dateTime, 'MM/DD/YYYY HH:mm:ss');

    // Add the minutesToExpire to the givenDateTime to get the expiration time
    const expirationDateTime = givenDateTime.add(minutesToExpire, 'minutes');

    // Get the current time
    const currentDateTime = moment();

    // Return false if expired (currentDateTime is greater than or equal to expirationDateTime)
    // Return true if it has not expired
    return currentDateTime.isBefore(expirationDateTime);
}

const addItemStore = (key, value) => {
    const cipherText = CryptoJS.AES.encrypt(value, secretKey).toString()
    let newObj = { key: key, value: cipherText }
    let initialvalue = !IsEmpty(localStorage.getItem('p-all')) ? JSON.parse(localStorage.getItem('p-all')) : []
    if (!IsEmpty(initialvalue)) {
        let filtervalue = initialvalue.filter((item) => { return item.key == key })
        if (!IsEmpty(filtervalue)) {
            const updatedArray = initialvalue.map(item => {
                return item.key == key ? { ...item, value: cipherText } : item
            });
            localStorage.setItem('p-all', JSON.stringify(updatedArray))
        }
        else {
            let updatedValue = [...initialvalue, newObj]
            localStorage.setItem('p-all', JSON.stringify(updatedValue))
        }
    }
    else {
        let updatedValue = [...initialvalue, newObj]
        localStorage.setItem('p-all', JSON.stringify(updatedValue))
    }
}


const updateItemStore = (key) => {
    localStorage.setItem('')
}

const getItemStore = (key) => {
    let data = JSON.parse(localStorage.getItem('p-all'))
    if (!IsEmpty(data)) {
        let filtervalue = data.filter((item) => { return item.key == key })
        return !IsEmpty(filtervalue) ? decryptData(filtervalue[0].value) : null
    }
}

const decryptData = (value) => {
    const bytes = CryptoJS.AES.decrypt(value, secretKey)
    const data = (bytes.toString(CryptoJS.enc.Utf8));
    return data
};


const removeItemStore = (key) => {
    localStorage.removeItem(key)
}
const clearStore = () => {
    localStorage.clear()

}


/**
 * function to check if user is logged in or not
 */
const checkLogin = () => {
    if (sessionStorage.authData) {
        return true;
    } else {
        return false;
    }
};

/**
 * function to set user authentication data
 */
const setAuthData = (data) => {
    const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    sessionStorage.setItem('authData', cipherText.toString());
};

/**
 * function to get user authentication data
 */
const getAuthData = () => {
    const data = sessionStorage.authData;
    if (data) {
        const bytes = CryptoJS.AES.decrypt(data.toString(), secretKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    } else {
        return {};
    }
};

/**
 * function to remove user authentication data
 */
const removeAuthData = () => {
    sessionStorage.removeItem('authData');
};

const authService = {
    checkLogin: checkLogin,
    // getAccessToken: getAccessToken,
    // getUserRole: getUserRole,
    // getUserId: getUserId,
    setAuthData: setAuthData,
    getAuthData: getAuthData,
    removeAuthData: removeAuthData,
};


const cnvertImageToURL = (imageUrl) => {
    // const imageUrl = "https://example.com/image.jpg"; // Replace this with your URL
    // const imgElement = document.createElement('img');
    // imgElement.src = imageUrl;
    // document.body.appendChild(imgElement);

    return fetch(imageUrl).then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.blob();
    }).then(blob => {
        let imageObject = { DocumentName: '', DocumentData: '' }
        let UUID = uuidv4();
        const file = new File([blob], "image.jpg", { type: "image/jpeg" });
        const DocumentName = UUID + '.' + file.name.split('.').pop().toLowerCase();
        // const res = e.target.files[0];

        imageObject.DocumentData = file
        imageObject.DocumentName = DocumentName

        return imageObject;
    }).catch(error => {
        return error;
    });
}

function DownloadFileClientside(Name, response) {
    const href = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', Name); //or any other extension
    document.body.appendChild(link);
    link.click();
}

export const onLogout = () => {
    window.location.href = '/home';
    localStorage.clear()
    sessionStorage.clear();

}

const languages = [
    { code: 'en', name: 'English' },
    { code: 'ar', name: 'Arabic' },
    { code: 'fr', name: 'French' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'hi', name: 'Hindi' },
];
const ThemeSupport = [
    { code: 'or', name: 'orange' },
    { code: 'bl', name: 'blue' },

];

const PAYMENTMETHOD = [
    {
        'Name': 'Super_Payemt',
        'subdescription': 'pay directly with your bank account app',
        'Image': Images.super_payment,
    },
    {
        'Name': 'Sripe_Payemt',
        'Image': Images.stripe_payment,
    }
]


const GetCurrentLocation = () => {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Successfully obtained the position
                resolve({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                // Failed to obtain the position
                reject(error);
            },
            {
                enableHighAccuracy: true, // Optional: enable high accuracy for better results
                timeout: 10000, // Optional: timeout after 10 seconds
                maximumAge: 0,  // Optional: no cache
            }
        );
    });
};
export const GetAddressfromLatLong = async (myLat, myLon) => {
    const response = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + myLat + ',' + myLon + '&key=' + "AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI");
    const responseJson = await response.json();
    return JSON.stringify(responseJson);
}

export const getAndsetCurrentLocation = async () => {
    let location = await GetCurrentLocation()
    let FullAddrees = await GetAddressfromLatLong(location.latitude, location.longitude)

    let finalAddress = JSON.parse(FullAddrees).results[0]
    if (!IsEmpty(finalAddress)) {
        const postalCode = await finalAddress.address_components.find((component) => {
            return component.types.includes("postal_code");
        });
        const CItyName = await finalAddress.address_components.find((component) => {
            return component.types.includes("locality");
        });
        const CountryName = await finalAddress.address_components.find((component) => {
            return component.types.includes("country");
        });
        const StateName = await finalAddress.address_components.find((component) => {
            return component.types.includes("administrative_area_level_1");
        });
        LocationObject.City = !IsEmpty(CItyName) ? CItyName.long_name : ''
        LocationObject.PinCode = !IsEmpty(postalCode) ? postalCode.long_name : ''
        LocationObject.Country = !IsEmpty(CountryName) ? CountryName.long_name : ''
        LocationObject.State = !IsEmpty(StateName) ? StateName.long_name : ''
        LocationObject.FullAddress = finalAddress.formatted_address

        //   setCurrentLocationRedux(LocationObject)
    }
}

export const getcurrentcountry = async () => {
    let location = await GetCurrentLocation()
    let FullAddrees = await GetAddressfromLatLong(location.latitude, location.longitude)

    let finalAddress = JSON.parse(FullAddrees).results[0]
    if (!IsEmpty(finalAddress)) {
        const postalCode = await finalAddress.address_components.find((component) => {
            return component.types.includes("postal_code");
        });
        const CItyName = await finalAddress.address_components.find((component) => {
            return component.types.includes("locality");
        });
        const CountryName = await finalAddress.address_components.find((component) => {
            return component.types.includes("country");
        });
        const StateName = await finalAddress.address_components.find((component) => {
            return component.types.includes("administrative_area_level_1");
        });
        LocationObject.City = !IsEmpty(CItyName) ? CItyName.long_name : ''
        LocationObject.PinCode = !IsEmpty(postalCode) ? postalCode.long_name : ''
        LocationObject.Country = !IsEmpty(CountryName) ? CountryName.long_name : ''
        LocationObject.State = !IsEmpty(StateName) ? StateName.long_name : ''
        LocationObject.FullAddress = finalAddress.formatted_address
        return LocationObject
    }
}


const StripePaymentGateway = {
    PublishKey: 'pk_test_51PAqiRBk6Un440W347PMf3QMc8Fyp2oFtwhElxC5odH5WtSeHChlSrdYBOKUWZQHwQHg3LAxFtZfSk22CygkmQir00mmc0odHr',
    SecretKey: 'sk_test_51PAqiRBk6Un440W3oVaFrInpHKYDXna5gSsQRJDpysLZ7L2uJTYgnjv1qgwYBCYSJ9HU7XxjZrCTudopkbyXr4gZ00CFdCY1my'
}


export const searchLocal = (searchArray, text) => {
    return searchArray.filter(order =>
        Object.values(order).some(value =>
            String(value).toLowerCase().includes(text)
        )
    );
}

export const downloadImage = (url, filename) => {
    // Open the image in a new window
    window.open(url, '_blank');
};

export function getCurrentYear() {
    return new Date().getFullYear();
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
export {
    AppConstant, GetCurrentLocation, Constanttitle, ThemeSupport, DOCUMENTS, DemoCoords, DocumentTypes, FileExtension, GetButtons, GetCMMSDateWithMyFormat, HomeScreenNavigate,
    GetDateWithMyFormat, GetDateWithCommonFormat, GetDateWithMM_DD_YYYY, ConvertToDateMMDDYYYY, GetMonthNumber, GetDateWithSingleDatePiker,
    GetLatLongFromAddress, IsEmpty, NumberDigitValidation, NumberValidation, formatPhoneNumber, ProductScreenNavigate, SearchSuggestions,
    isValidURL, websiteURLFromat, DefaultLatLng, PAYMENTMETHOD, StripePaymentGateway,
    TableColor, addItemStore, chipData, clearStore, defaultDateFormats, monthNames, facebookConfig, getItemStore, isEmptyObject, removeItemStore,
    storageKeys, capitalizeFirstLetter, StaticApiToken, languages, updateItemStore, authService, cnvertImageToURL, DownloadFileClientside
};