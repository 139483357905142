export const FontWeight = {
  thin: "100",
  ultraLight: "200",
  light: "300",
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
  heavy: "800",
  black: "900",
};
export const lineHeight = {
  regular: 1.2,
  medium: 1.5,
  height: 2,
  small: 1,
  xsmall: 0.5,
  xl: 2.5
};

/**
 * Typography setting
 * - This font weight will be used for all template
 * - Check more how to use typography in url below
 * @url http://passionui.com/docs/felix-travel/theming
 */
export const Typography = {
  header: {
    fontSize: 50,
    fontWeight: FontWeight.bold,
  },
  header1: {
    fontSize: 62,
    fontWeight: FontWeight.bold,
  },
  heading2: {
    fontSize: 32,
    fontWeight: FontWeight.bold,
  },
  heading: {
    fontSize: 38,
    fontWeight: FontWeight.bold,
  },
  title: {
    fontSize: 26,
    fontWeight: FontWeight.medium,
  },
  title1: {
    fontSize: 28,
    fontWeight: FontWeight.bold,
  },
  title2: {
    fontSize: 22,
    fontWeight: FontWeight.bold,
  },
  title3: {
    fontSize: 20,
    fontWeight: FontWeight.bold,
  },
  title4: {
    fontSize: 30,
    fontWeight: FontWeight.bold,
  },
  title5: {
    fontSize: 24,
    fontWeight: FontWeight.bold,
  },
  callout: {
    fontSize: 19,
    fontWeight: FontWeight.regular,
  },
  headline: {
    fontSize: 18,
    fontWeight: FontWeight.bold,
  },
  body1: {
    fontSize: 17,
    fontWeight: FontWeight.regular,
  },
  body2: {
    fontSize: 16,
    fontWeight: FontWeight.regular,
  },
  head: {
    fontSize: 15,
    fontWeight: FontWeight.regular,
  },
  subhead: {
    fontSize: 14,
    fontWeight: FontWeight.regular,
  },
  footnote: {
    fontSize: 13,
    fontWeight: FontWeight.regular,
  },
  caption1: {
    fontSize: 12,
    fontWeight: FontWeight.regular,
  },
  caption2: {
    fontSize: 11,
    fontWeight: FontWeight.regular,
  },
  overline: {
    fontSize: 10,
    fontWeight: FontWeight.regular,
  },
};
