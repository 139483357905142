import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const InputContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
}));

const CountryCode = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '10px',
  transform: 'translateY(-50%)',
  color: '#888',
  fontWeight: 'bold',
}));

const MobileInput = React.forwardRef(({ value, onChange, ...props }, ref) => {
  const [countryCode, setCountryCode] = React.useState('+1');

  const handleCountryChange = (newValue, countryData) => {
    setCountryCode(`+${countryData.dialCode}`);

    // The `newValue` contains both country code and phone number
    // We're passing the full value back for rendering, but separating the number for state.
    const phoneNumber = newValue.slice(countryData.dialCode.length);
    onChange(phoneNumber, `+${countryData.dialCode}`);
  };

  return (
    <InputContainer>
      <CountryCode>{countryCode}</CountryCode>
      <PhoneInput
        country={'in'}
        value={value}
        onChange={handleCountryChange}
        inputStyle={{
          padding: '10px 10px 10px 40px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          width: '100%',
          height: '48px',
        }}
        dropdownStyle={{ border: '1px solid #ccc' }}
        ref={ref}
        {...props}
      />
    </InputContainer>
  );
});

export default MobileInput;
