import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {BaseColor, FontFamily, FontWeight, useTheme } from '../../config';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    TextFieldStyle: {
        width: "100%",
        fontSize: 15,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.heavy,
        borderRadius: '8px',
        backgroundColor: 'trasparent'
    }
}));


const CssTextField = withStyles({
    root: {
        '& label': {
            color: BaseColor.DarkGrayTextColor,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.bold,
            letterSpacing: '0.5px',
            textOverflow: "ellipsis",
            whiteSpace: 'nowrap',
            overflowx: 'hidden'
        },
        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px'
        },
        '& label.Mui-focused': {
            color: BaseColor.DarkGrayTextColor,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px'
            }
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '0px',
                border: 'none',
                borderBottom: `1px solid`,
                borderColor: BaseColor.DarkGrayTextColor,
                color: BaseColor.DarkGrayTextColor
            },
            '&:hover fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.DarkGrayTextColor
            },
            '&.Mui-focused fieldset': {
                borderWidth: '1px',
                borderColor: BaseColor.DarkGrayTextColor
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 15px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.blackColor,
                fontWeight: FontWeight.regular
            }
        }
    }
})(TextField);

const CustomInputComponent = props => {
    const { maxLength, label, value, shrink, disabled, required, handleClear, placeholder } = props;
    // let BaseColor = useTheme().theme.colors

  
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <CssTextField
                InputProps={{
                    maxLength: maxLength,
                    endAdornment: handleClear && (
                        <IconButton onClick={handleClear} edge="end">
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                inputProps={{ maxLength }}  // Ensure maxLength is passed here
                InputLabelProps={{ shrink: shrink }}
                label={required ? <span>{label}<sup>*</sup></span> : label}
                required={required}
                fullWidth

                className={classes.TextFieldStyle}
                value={value}
                disabled={disabled}
                variant="outlined"
                placeholder={placeholder}  // Add placeholder here
                {...props}
            />
        </div>
    );
}

CustomInputComponent.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    value: PropTypes.string,
    variant: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.bool,
    placeholder: PropTypes.string,  // Add placeholder to propTypes
};

CustomInputComponent.defaultProps = {
    style: {},
    maxLength: 15000,
    value: '',
    name: '',
    label: '',
    variant: 'outlined',
    onChange: () => { },
    required: false,
    error: false,
    placeholder: '',  // Add placeholder to defaultProps
}

export default CustomInputComponent;
