import React, { useEffect } from "react";

const ZohoChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "zsiqscript";
    script.defer = true;
    script.src = "https://salesiq.zoho.com/widget";
    document.getElementsByTagName("head")[0].appendChild(script);

    window.$zoho = window.$zoho || {};
    window.$zoho.salesiq = window.$zoho.salesiq || {
      widgetcode: "siq3f44974b05c497e60a5690bedb7e2038091455240c670ece1cfefdf0587dccfc",
      values: {},
      ready: function () { }
    };

    return () => {
      // Cleanup by removing the script if the component unmounts
      document.getElementById("zsiqscript")?.remove();
    };
  }, []);

  return null;
};

export default ZohoChat;
