import React from 'react';
import './destinationcard.css';
import { Button, Text } from '../../components';
import { useTranslation } from 'react-i18next';

export default function DestinationCard({ DestinationsData, PurchaseeSIM = {} }) {
  const { t } = useTranslation();

  return (
    <div>
      <section className="articles">
        {DestinationsData.length > 0 ? (
          DestinationsData.map((item, ind) => (
            <article key={ind}>
              <div className="article-wrapper">
                <div style={{margin: "40px"}}>
                  <img className='portfolio-image' src={item.CountryImage} width='100%' height='100%' />
                </div>
                <div className="article-body">
                  <Text medium whiteColor heading2 >{item.CountryName}</Text><br />
                  <Text medium whiteColor body2>{item.price}</Text>
                  <div className='DestinationCard-button'>
                    <Button onClick={() =>  PurchaseeSIM(item)} title={t('View_Bundle')} round />
                  </div>
                </div>
              </div>
            </article>
          ))
        ) : (
          <div className="no-results">
            <Text bold body2 blackColor>No countries match your search or selected range.</Text>
          </div>
        )}
      </section>
    </div>

  );
}
