// src/store.js
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { thunk } from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { storageKeys } from '../config/appConstant';

// Example secret key
const secretKey = 'Elit-eSIM-v1';



// Action Types
const SET_LANGUAGE = storageKeys.Language;
const SET_THEME = storageKeys.Theme;
const SET_DIRECTION = storageKeys.Direction;
const SET_CART = storageKeys.CartCount;
const SET_USERDATA = storageKeys.UserDetail;
const SET_DARKMODE = storageKeys.DarkMode;
const SET_ISLOGIN = storageKeys.IsLoged;
const SET_BUNDLE = storageKeys.SelectBundle;
const SET_ICCID = storageKeys.SET_ICCID;
const SET_LOGINMODAL = storageKeys.OpenLoginModal;

// Actions
export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  payload: language,
});

export const setBundle = (S_B) => ({
  type: SET_BUNDLE,
  payload: S_B,
});

export const setLogin = (il) => ({
  type: SET_ISLOGIN,
  payload: il,
});

export const setTheme = (theme) => ({
  type: SET_THEME,
  payload: theme,
});

export const setDark = (force_dark) => ({
  type: SET_DARKMODE,
  payload: force_dark,
});

export const setDirection = (dir) => ({
  type: SET_DIRECTION,
  payload: dir,
});

export const setloginDetail = (userdetail) => ({
  type: SET_USERDATA,
  payload: userdetail,
});

export const setCart = (cart) => ({
  type: SET_CART,
  payload: cart,
});

export const setICCID = (iccid) => ({
  type: SET_ICCID,
  payload: iccid,
});

export const setLoginModal = (ol) => ({
  type: SET_LOGINMODAL,
  payload: ol,
});

// Reducers
const languageReducer = (state = 'en', action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

const BundleReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_BUNDLE:
      return action.payload;
    default:
      return state;
  }
};

const cartReducer = (state = 0, action) => {
  switch (action.type) {
    case SET_CART:
      return parseInt(action.payload, 10);
    default:
      return state;
  }
};

const themeReducer = (state = 'orange', action) => {
  switch (action.type) {
    case SET_THEME:
      return action.payload;
    default:
      return state;
  }
};
const isLoginReducer = (state = false, action) => {
  switch (action.type) {
    case SET_ISLOGIN:
      return action.payload;
    default:
      return state;
  }
};

const DarkReducer = (state = false, action) => {
  switch (action.type) {
    case SET_DARKMODE:
      return action.payload === true;
    default:
      return state;
  }
};

const directionReducer = (state = 'ltr', action) => {
  switch (action.type) {
    case SET_DIRECTION:
      return action.payload;
    default:
      return state;
  }
};

const userDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_USERDATA:
      return action.payload;
    default:
      return state;
  }
};

const iccidReducer = (state = '', action) => {
  switch (action.type) {
    case SET_ICCID:
      return action.payload;
    default:
      return state;
  }
};

const loginModalOpenReducer = (state = false, action) => {
  switch (action.type) {
    case SET_LOGINMODAL:
      return action.payload;
    default:
      return state;
  }
};

// Root Reducer
const rootReducer = combineReducers({
  language: languageReducer,
  S_T: BundleReducer,
  cart: cartReducer,
  userdetail: userDetailReducer,
  dir: directionReducer,
  theme: themeReducer,
  il: isLoginReducer,
  force_dark: DarkReducer,
  iccid: iccidReducer,
  ol: loginModalOpenReducer,
});

// Persist Config with Encryption Transform
const persistConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: secretKey,
      onError: function (error) {
        console.error('Encryption error:', error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
