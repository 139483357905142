import Loadable from 'react-loadable';
import { Loading } from '../components';

export const ScreenLayout = Loadable({
  loader: () => import('../screens/ScreenLayout/ScreenLayout'),
  loading: Loading,
});

export const MixScreen = Loadable({
  loader: () => import('../screens/MixScreen'),
  loading: Loading,
});
export const Home = Loadable({
  loader: () => import('../screens/HomeScreen/Home.js'),
  loading: Loading,
});
export const Destination = Loadable({
  loader: () => import('../screens/Destination/Destination'),
  loading: Loading,
});
export const GetHelp = Loadable({
  loader: () => import('../screens/GetHelp/GetHelp'),
  loading: Loading,
});
export const ESIMOrders = Loadable({
  loader: () => import('../screens/ESIMOrders/ESIMOrders.js'),
  loading: Loading,
});
export const AboutUs = Loadable({
  loader: () => import('../screens/AboutUs/AboutUs'),
  loading: Loading,
});
export const ContactUs = Loadable({
  loader: () => import('../screens/ContactUs/ContactUs'),
  loading: Loading,
});
export const SignIn = Loadable({
  loader: () => import('../screens/SignIn/SignIn.js'),
  loading: Loading,
});
export const SignUp = Loadable({
  loader: () => import('../screens/SignUp/SignUp.js'),
  loading: Loading,
});
export const OTP = Loadable({
  loader: () => import('../screens/OTP/Otp.js'),
  loading: Loading,
});
export const Cart = Loadable({
  loader: () => import('../screens/Cart/Cart.js'),
  loading: Loading,
});
export const PaymentSuccess = Loadable({
  loader: () => import('../screens/PaymentSuccess/PaymentSuccess'),
  loading: Loading,
});
export const PaymentFailure = Loadable({
  loader: () => import('../screens/PaymentFailure/PaymentFailure'),
  loading: Loading,
});
export const ESIMDetail = Loadable({
  loader: () => import('../screens/ESIMDetail/ESIMDetail'),
  loading: Loading,
});
export const TermsAndCondition = Loadable({
  loader: () => import('../screens/TermsAndCondition/TermsAndCondition.js'),
  loading: Loading,
});
export const ChangePassword = Loadable({
  loader: () => import('../screens/ChangePassword/ChangePassword'),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import('../screens/Notfound/NotFound.js'),
  loading: Loading,
});
export const WelcomePage = Loadable({
  loader: () => import('../screens/WelcomePage/Welcome.js'),
  loading: Loading,
});
export const PrivacyPolicy = Loadable({
  loader: () => import('../screens/PrivacyPolicy/PrivacyPolicy.js'),
  loading: Loading,
});