// src/components/ESIMCard.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '../../components';
import { IsEmpty } from '../../config/appConstant';
import './ESIMCard.css';

const ESIMCard = ({ title, content, ViewDetailClick, listItems, buttonText, item, buttonLink }) => {
  const { t } = useTranslation();

  const downloadImage = async (imageUrl, fileName) => {
    try {
      const response = await fetch(imageUrl); // Fetch the image
      if (!response.ok) {
        throw new Error('Network response was not ok'); // Handle errors
      }
      const blob = await response.blob(); // Convert response to Blob
      const link = document.createElement('a'); // Create a link element
      link.href = URL.createObjectURL(blob); // Create an object URL for the Blob
      link.download = fileName; // Set the filename
      document.body.appendChild(link); // Append link to the body
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up
      URL.revokeObjectURL(link.href); // Release the object URL
    } catch (error) {
      console.error("Error downloading the image:", error); // Log errors
    }
  };


  return (
    <div className="ESIMCard-mainContainer">
      <div className='ESIMCard-LeftContainer'>
        <div className="ESIMCard-title"><Text lineheightsmall bold primaryColor title5>{'ESIM :: ' + title}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'CountryName : ' + item.CountryName}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'BundleName : ' + item.BundleName}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'EmailAddress : ' + item.EmailAddress}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'OrderedDateTime : ' + item.OrderedDateTime}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'VATPercentage : ' + item.VATPercentage + '%'}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'BundleDescription : ' + item.BundleDescription}</Text></div>
        <div className="ESIMCard-content tag-flex" >
          <Text lineheightsmall regular body2>OrderStatus : </Text>
          <div className={item.OrderStatus === 'Failure' ? 'emsimcard-tag-failure' : 'emsimcard-tag-success'} >
            <Text lineheightsmall whiteColor regular body2> {item.OrderStatus}</Text>
          </div>
        </div>
      </div>
      {!IsEmpty(item.QRCode) &&
        <div className='ESIMCard-rightContainer'>
          <img src={item.QRCode} alt='QR Code' className='ESIMCard-logo' />
          <div className='esimcard-button-flex'>
            <Button onClick={() => downloadImage(item.QRCode, `QRCode_${item.title}.png`)} full title={t('Downloadqr')} />
            <Button onClick={ViewDetailClick} full title={t('View_Detail')} />
          </div>
        </div>
      }
    </div>
  );
};

export default ESIMCard;
