import React from 'react'
import { Card, Text } from "../../components";
import { Images, useTheme } from "../../config";
import { useTranslation } from 'react-i18next';
import './Nodata.css'
export default function Nodata({ Message='No data Found', props, }) {
    const BaseColor= useTheme().theme.colors

    const { t } = useTranslation();
    return (
        <Card className='Nodata-main-container' CardColor={BaseColor.card}>
            <img src={Images.mainlogo} alt='Technolee' className='Nodata-logo' />
            <div className='Nodata-Message'>
            <Text >{t(Message)}</Text>
            </div>
        </Card>
    )
}
